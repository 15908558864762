import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export type GlideApiResponse<T> = T extends Blob
  ? Blob
  : {
      data: T;
      status: number;
    };

export default abstract class BaseApi {
  protected axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  get<T = unknown, R = GlideApiResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ) {
    return this.axios.get<T, R>(url, config);
  }

  post<T, R = GlideApiResponse<T>>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ) {
    return this.axios.post<T, R>(url, data, config);
  }

  delete<Res = unknown>(
    ...args: Parameters<AxiosInstance['delete']>
  ): Promise<GlideApiResponse<Res>> {
    return this.axios.delete(...args);
  }

  getFullUri(config: AxiosRequestConfig): string {
    // Current axios version does not provide an api to get the full uri so we need to add the domain manually
    const LEADING_SLASHES_RE = /^\/+/;

    const url = `${window.location.origin}${this.axios.defaults.baseURL}${(
      config.url as string
    ).replace(LEADING_SLASHES_RE, '')}`;
    return this.axios.getUri({ ...config, url });
  }
}
